import React from 'react'
import styles from './AboutUs.module.css'
import AboutUsImg from 'assets/imgs/aboutUsImg.png'
import { useTranslation } from 'react-i18next'
function AboutUs() {
  const {t} =useTranslation()
  return (
    <section className={styles['about-us']} id='about-us'>
        <div className={styles['about-us__wrapper']}>
            <div className={styles['about-us__content-wrapper']}>
                <img src={AboutUsImg} alt='about us image' className={styles['about-us__img']} />
                <div className={styles['about-us__content']}>
                    <h2 className={styles['about-us__title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>{t('who_are_we')}</h2>
                    <h3 className={styles['about-us__sub-title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>{t('our_story')}</h3>
                    <p className={styles['about-us__description']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>{t('upon_the_client')}</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AboutUs