import React, { useEffect } from 'react'
import i18next from 'i18next'
import Cookies from 'js-cookie'
import { useDispatch} from 'react-redux'
import { changeLanguageAction } from 'reduxStore/Global/GlobalActions'
import stylesNav from '../../Layout/NavBar/NavBar.module.css'
import { useTranslation } from 'react-i18next'

function ChangeLangueList() {
    const languages=[
        {
            code:'ar',
            name:'العربية',
            country_code:'sa',
            dir:'rtl'
        },
        {
            code:'br',
            name:'english',
            country_code:'gb',
            dir:'ltr'
        },
        
    ]
    const dispatch= useDispatch()
    const {t} =useTranslation()
    const currentLanguageCode = Cookies.get('i18next') || 'ar'
    const currentLanguage = languages.find(l=> l.code === currentLanguageCode)
    useEffect(()=>{
        document.body.dir=currentLanguage.dir ||'rtl'
    },[currentLanguage])
    let changeLanguage=(code)=>{
        i18next.changeLanguage(code)
        changeLanguageAction(dispatch,code)
        // window.location.reload()
    }
  return (
    <>
        <button className={stylesNav['navbar-menu-link']} onClick={(code)=>{changeLanguage( Cookies.get('i18next') =='br'?'ar':'br')}}>{t('lang')}</button>
    </>
  )
}

export default ChangeLangueList