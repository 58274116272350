import React, { useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import Header from 'components/Home/Header/Header'
import AboutUs from 'components/Home/AboutUs/AboutUs'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useTranslation } from 'react-i18next'
import AOS from 'aos'

function Home() {
  const {t} =useTranslation()
  
  useEffect(()=>{
    setTimeout(()=>{
      AOS.refresh()
    },400)
  },[])
  return (
    <>
    <motion.div 
      init={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0}}>
        <MetaTags>
              <title>{t('Wafr')}</title>
        </MetaTags>
        <Header/>
        <AboutUs/>
        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Home