import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import wafrLogo from 'assets/imgs/wafrLogo.png'
import {ReactComponent as MenuIcon} from 'assets/icons/menu.svg'
import {  Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
function NavBar({toggleSideNavBar}) {
    const {t} =useTranslation()
  return (
    <Navbar  className={styles['navbar']}>
        <div className='d-flex flex-column w-100'>

            {/* <div className={styles['navbar-top']}>
                <Container fluid='lg'>
                    <div className={styles['navbar-top__wrapper']}>
                        <div className='d-none d-lg-flex'>
                            <span className={styles['navbar-top__language-span']}>{t('language')}</span>:<ChangeLangueList/>
                        </div>
                    </div>
                </Container>
            </div> */}
            <div className={styles['navbar-bottom']}>
                <Container className={styles['navbar__wrapper' ]}>
                    {/* <div className='h-100 d-flex d-lg-none align-items-center justify-content-between w-100'>
                        <div className={`${styles['navbar-top__language-wrapper']} ${styles['navbar-top__language-wrapper--responsive']}`}>
                            <ChangeLangueList/>
                        </div>
                        <button className={`${styles['navbar__menu-btn']} `} onClick={toggleSideNavBar}>
                            <MenuIcon/>
                        </button>
                    </div> */}
                    <NavLink to='/' className='ms-lg-auto h-100'><img src={wafrLogo} alt='logo' className={styles['navbar__logo']}/></NavLink>
                    <Navbar.Collapse className='h-100 navbar-collapse collapse d-flex justify-content-end'>
                        <ul className={`${styles['navbar-nav']} navbar-nav  h-100 d-flex align-items-center`}>
                            {/* <li className={`${styles["navbar__menu-items"]} nav-item ms-lg-auto`}>
                                <NavLink to='/'><FishLogo className={styles['navbar__logo']}/></NavLink>
                            </li> */}
                            <li className={`${styles["navbar__menu-items"]} nav-item `}>
                                <Link to='/' className={`${styles['navbar-menu-link']} nav-link`}>{t('emirates_fish')}</Link>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <ChangeLangueList/>
                                {/* <a href='/#about-us' className={`${styles['navbar-menu-link']} nav-link`}>{t('who_are_we')}</a> */}
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <Link to='/terms-conditions' className={`${styles['navbar-menu-link--terms']} nav-link`}>{t('terms')}</Link>
                            </li>
                            
                        </ul> 
                    </Navbar.Collapse>
                </Container>
            </div>

        </div>


    </Navbar>
  )
}

export default NavBar