import React,{ useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from "views/Home/Home";
import NavBar from 'components/Global/Layout/NavBar/NavBar';
import SideBar from 'components/Global/Layout/SideBar/SideBar';
import { ToastContainer } from 'react-toastify';
import TermsAndConditions from 'views/TermsAndConditions/TermsAndConditions';
import { AnimatePresence } from 'framer-motion';
function AnimatedRoutes() {
    const location = useLocation()
    const [isSideBarVisible , setIsSideBarVisible] =useState(false)
    let toggleSideNavBar =(type)=>[
      setIsSideBarVisible(type==='open')
    ]
  return (
    <>
            <NavBar toggleSideNavBar={()=>{toggleSideNavBar('open')}}/>
            <SideBar isSideBarVisible={isSideBarVisible}  toggleSideNavBar={()=>{toggleSideNavBar('close')}}/>
            <div className="App">
            <ToastContainer />
            <AnimatePresence>
              <Routes location={location} key={location.pathname}>
                  <Route path='/' exact element={<Home/>}></Route>
                  <Route path='/terms-conditions' element={<TermsAndConditions/>}></Route>
              </Routes>
            </AnimatePresence>
            </div>
    </>
  )
}

export default AnimatedRoutes